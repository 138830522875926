.dashContainer {
  display: flex;
  align-items: flex-start;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1.5%;
  padding-top: 3%;
  flex-wrap: wrap;
  background-color: #f4f5f8;
  background-position: fill;
  min-height: 100vh;
}

.dashCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  align-content: center;
  gap: 5px;
}

.dashCardHeading {
  display: flex;
  justify-content: space-between;
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .dashCardContainer {
    width: 30%;
    /* Adjust the width based on your design */
  }
}

@media screen and (max-width: 768px) {
  .dashCardContainer {
    width: 45%;
    /* Adjust the width based on your design */
  }
}

@media screen and (max-width: 480px) {
  .dashCardContainer {
    width: 100%;
    /* Full width on smaller screens */
  }

  .dashCardHeading {
    flex-direction: column;
    align-items: flex-start;
  }
}